<template>
  <v-card
    class="outset welfare-card mx-10 my-5 px-5 py-5"
  >
    <v-card-title>
      ポイント交換申請管理
      <v-spacer />
      <v-text-field
        v-model="searchWord"
        append-icon="mdi-magnify"
        label="検索"
        single-line
        hide-details
      />
    </v-card-title>
    <v-data-table
      :headers="orderTableHeaders"
      :items="orders"
      :search="searchWord"
      :style="styles.app"
      :items-per-page="20"
      no-results-text="検索結果が見つかりません"
      no-data-text="データがありません"
      :footer-props="{
        showFirstLastPage: true,
        disableItemsPerPage: true,
        itemsPerPageOptions: [20, -1],
        itemsPerPageText: '表示件数'
      }"
      @click:row="showDetail"
    >
      <template v-slot:item.orderStatus="{ item }">
        <v-chip
          v-if="item.orderStatus === ORDER_STATUS.OPEN"
          color="info"
          label
        >
          {{ ORDER_STATUS_LABEL[item.orderStatus] }}
        </v-chip>
        <v-chip
          v-else
          label
        >
          {{ ORDER_STATUS_LABEL[item.orderStatus] }}
        </v-chip>
      </template>
      <template v-slot:item.updatedAt="{ item }">
        <div
          v-if="item.status !== '準備中'"
        >
          {{ item.updatedAt }}
        </div>
        <div
          v-else
        >
          -
        </div>
      </template>
      <template v-slot:footer.page-text="props">
        {{ props.pageStart }} - {{ props.pageStop }} (全{{ props.itemsLength }}件)
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import CommonMixin from '@/mixins/CommonMixin'
import { ORDER_STATUS, ORDER_STATUS_LABEL } from '@/constants'

export default {
  mixins: [
    CommonMixin
  ],

  props: {
    orderTableHeaders: {
      type: Array,
      default: () => []
    },
    orders: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    searchWord: ''
  }),

  computed: {
    ORDER_STATUS () {
      return ORDER_STATUS
    },
    ORDER_STATUS_LABEL () {
      return ORDER_STATUS_LABEL
    }
  },

  methods: {
    showDetail (row) {
      this.$emit('showDetail', this, row.orderNumber)
    }
  }
}
</script>
