var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"outset welfare-card mx-10 my-5 px-5 py-5"},[_c('v-card-title',[_vm._v(" ポイント交換申請管理 "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"検索","single-line":"","hide-details":""},model:{value:(_vm.searchWord),callback:function ($$v) {_vm.searchWord=$$v},expression:"searchWord"}})],1),_c('v-data-table',{style:(_vm.styles.app),attrs:{"headers":_vm.orderTableHeaders,"items":_vm.orders,"search":_vm.searchWord,"items-per-page":20,"no-results-text":"検索結果が見つかりません","no-data-text":"データがありません","footer-props":{
      showFirstLastPage: true,
      disableItemsPerPage: true,
      itemsPerPageOptions: [20, -1],
      itemsPerPageText: '表示件数'
    }},on:{"click:row":_vm.showDetail},scopedSlots:_vm._u([{key:"item.orderStatus",fn:function(ref){
    var item = ref.item;
return [(item.orderStatus === _vm.ORDER_STATUS.OPEN)?_c('v-chip',{attrs:{"color":"info","label":""}},[_vm._v(" "+_vm._s(_vm.ORDER_STATUS_LABEL[item.orderStatus])+" ")]):_c('v-chip',{attrs:{"label":""}},[_vm._v(" "+_vm._s(_vm.ORDER_STATUS_LABEL[item.orderStatus])+" ")])]}},{key:"item.updatedAt",fn:function(ref){
    var item = ref.item;
return [(item.status !== '準備中')?_c('div',[_vm._v(" "+_vm._s(item.updatedAt)+" ")]):_c('div',[_vm._v(" - ")])]}},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" (全"+_vm._s(props.itemsLength)+"件) ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }