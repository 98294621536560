import store from '@/store'
import Presenter from '@/components/pages/manage/order/list/Presenter'

export default {
  data: () => ({
    orders: [],
    orderTableHeaders: [
      { text: '申請の状態', value: 'orderStatus' },
      { text: '注文番号', value: 'orderNumber' },
      { text: '交換申請日', value: 'createdAt' },
      { text: '交換申請者', value: 'user' },
      { text: '最終更新日', value: 'updatedAt' }
    ]
  }),

  async created () {
    this.orders = await store.dispatch('order/getList')
  },

  render (h) {
    return h(Presenter, {
      props: {
        orders: this.orders,
        orderTableHeaders: this.orderTableHeaders
      },

      on: {
        showDetail (presenter, orderNumber) {
          presenter.$router.push({ name: 'OrderDetail', params: { orderNumber: orderNumber }})
        }
      }
    })
  }
}
